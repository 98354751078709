import React, { Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Spinner from '../components/Spinner'
const Home = React.lazy(() => import('../pages/home'))
const Auth = React.lazy(() => import('../components/Auth'))
const AuthSuccess = React.lazy(() => import('../pages/auth'))
const Login = React.lazy(() => import('../pages/login'))
const Register = React.lazy(() => import('../pages/register'))
const InstructorHome = React.lazy(() => import('../pages/instructor/home'))
const InstructorCourses = React.lazy(
  () => import('../pages/instructor/courses')
)
const CourseCreator = React.lazy(
  () => import('../pages/instructor/courseCreator')
)
const editCourse = React.lazy(() => import('../pages/instructor/editCourse'))
const EditCourseAudience = React.lazy(
  () => import('../pages/instructor/editCourse/editCourseAudience')
)
const EditCourseLandingPage = React.lazy(
  () => import('../pages/instructor/editCourse/editCourseLandingPage')
)
const EditCourseStructure = React.lazy(
  () => import('../pages/instructor/editCourse/editCourseStructure')
)
const CoursePreview = React.lazy(
  () => import('../pages/instructor/coursePreview')
)
const CourseSettings = React.lazy(
  () => import('../pages/instructor/courseSettings')
)
const LandingPagePreview = React.lazy(
  () => import('../pages/instructor/landingCoursePreview')
)
const InstructorProfile = React.lazy(
  () => import('../pages/instructor/InstructorProfile')
)
const CoursePricing = React.lazy(
  () => import('../pages/instructor/editCourse/CoursePricing')
)
const ForCheck = React.lazy(
  () => import('../pages/instructor/editCourse/ForCheck')
)
const TestVideo = React.lazy(() => import('../pages/instructor/TestVideo'))
const Notifications = React.lazy(() => import('../pages/notifications'))
const CourseSearch = React.lazy(() => import('../pages/courseSearch'))
const Course = React.lazy(() => import('../pages/course'))
const Course2 = React.lazy(() => import('../pages/course2'))
const MainLayout = React.lazy(() => import('../layout/MainLayout'))
const InstructorAbout = React.lazy(() => import('../pages/instructor/about'))
const CartPage = React.lazy(() => import('../pages/cart'))
const LearningPage = React.lazy(() => import('../pages/learning'))
const CourseLearningPage = React.lazy(() => import('../pages/courseLearning'))
const ScrollToTop = React.lazy(() => import('../components/ScrollToTop'))
const Footer = React.lazy(() => import('../components/Footer'))
const EarningPage = React.lazy(() => import('../pages/instructor/earning'))
const PurchaseHistory = React.lazy(() => import('../pages/purchaseHistory'))
const InvitePage = React.lazy(() => import('../pages/invite'))
const AccountSettingsPage = React.lazy(() => import('../pages/accountSettings'))
const InfoPage = React.lazy(() => import('../pages/instructor/info'))
const ContactUsPage = React.lazy(() => import('../pages/contactus'))
const ContactUsPageLaxy = React.lazy(() => import('../pages/contactus'))
const AboutUsPage = React.lazy(() => import('../pages/aboutus'))
const TermsOfUsePage = React.lazy(() => import('../pages/termsofuse'))
const PrivacyPolicyPage = React.lazy(() => import('../pages/privacyPolicy'))
const WelcomePage = React.lazy(() => import('../pages/welcome'))
const AggrementPage = React.lazy(() => import('../pages/instructor/aggrement'))
const ComingSoon = React.lazy(() => import('../pages/comingSoon'))

export const Routes = {
  welcome: '/welcome',
  learning: '/learning',
  invite: '/invite',
  comingSoon: '/soon',
  profile: '/profile',
  accountSettings: '/account-settings',
  purchases: '/purchases',
  contact: '/contact-us',
  about: '/about-us',
  termsOfUse: '/terms-of-use',
  privacy: '/privacy-policy',
  accessibility: '/accessibility',
  courseLearning: (learningId: string) => `/learning/${learningId}`,
  cart: '/cart',
  courseSearch: '/courseSearch',
  login: '/login',
  register: '/register',
  myLearning: '/my-learning',
  tokens: '/tokens',
  settings: '/settings',
  home: '/',
  auth: '/auth',
  course: (courseId: string) => {
    return `/course/${courseId}`
  },
  instructor: {
    aggrement: '/instructor/aggrement',
    info: (instructorId: string) => `/instructor/${instructorId}`,
    id: (instructorId: string) => `/instructor/${instructorId}`,
    testVideo: '/instructor/testVideo',
    earning: '/instructor/earning',
    editCourse: {
      '/': '/instructor/editCourse',
      audience: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/audience}`,
      structure: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/structure}`,

      landingPage: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/landingPage}`,
      price: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/price}`,
      check: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/check}`,
      coursePreview: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/coursePreview}`,
      landigPagePreview: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/landigPagePreview`,
      settings: (editCourseId: string) =>
        `/instructor/editCourse/${editCourseId}/settings`,
    },
    notifications: '/instructor/notifications',
    courses: '/instructor/courses',
    home: '/instructor',
    sales: '/instructor/sales',
    profile: '/instructor/profile',
    test: '/instructor/test',
    messages: '/instructor/messages',
    courseCreator: '/instructor/courseCreator',
  },
}

export const ApiRoutes = {
  contactus: '/contactus',
  course: (courseId: string) => `/courses/${courseId}`,
  files: '/files',
  auth: {
    me: '/auth/me',
    jwt: {
      register: '/auth/jwt/register',
      login: '/auth/jwt/login',
    },
  },
  instructor: (instructorId: string) => `/instructors/${instructorId}`,
  users: {
    password: '/users/password',
    purchaseHistory: '/users/purchaseHistory',
    earning: '/users/earning',
    learning: (learningId: string) => `/users/learning/${learningId}`,
    '/': '/users',
    cart: '/users/cart',
    createdCourses: {
      '/': '/users/createdCourses',
      id: (createdCourseId: string) =>
        `/users/createdCourses/${createdCourseId}`,
      sections: {
        '/': (createdCourseId: string) =>
          `/users/createdCourses/${createdCourseId}/sections`,
        id: (createdCourseId: string, sectionId: string) =>
          `/users/createdCourses/${createdCourseId}/sections/${sectionId}`,
        lectures: {
          '/': (createdCourseId: string, sectionId: string) =>
            `/users/createdCourses/${createdCourseId}/sections/${sectionId}/lectures`,
          id: (createdCourseId: string, sectionId: string, lectureId: string) =>
            `/users/createdCourses/${createdCourseId}/sections/${sectionId}/lectures/${lectureId}`,
        },
      },
    },
  },
  courses: {
    '/': '/courses',
  },
  categories: {
    '/': '/categories',
  },
}

const Router: React.FC = () => {
  toast.configure({ autoClose: 10000 })
  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex justify-center items-center">
          <div className="w-fit h-fit">
            <Spinner />{' '}
          </div>
        </div>
      }
    >
      <BrowserRouter>
        <ToastContainer
          className=""
          closeButton={false}
          position="bottom-center"
        />
        <Auth />
        <ScrollToTop />
        <Switch>
          <Route exact path={Routes.about} component={AboutUsPage} />
          <Route
            exact
            path={Routes.instructor.aggrement}
            component={AggrementPage}
          />
          <Route
            exact
            path={Routes.courseLearning(':learningId')}
            component={CourseLearningPage}
          />
          <Route exact path={Routes.comingSoon} component={ComingSoon} />
          <Route exact path={Routes.termsOfUse} component={TermsOfUsePage} />
          <Route exact path={Routes.privacy} component={PrivacyPolicyPage} />
          <Route
            exact
            path={Routes.accountSettings}
            component={AccountSettingsPage}
          />
          <Route exact path={Routes.invite} component={InvitePage} />
          <Route
            exact
            path={`${Routes.instructor.editCourse['/']}/:editCourseId`}
            component={editCourse}
          />
          <Route
            exact
            path={`${Routes.purchases}`}
            component={PurchaseHistory}
          />
          <Route exact path={Routes.learning} component={LearningPage} />
          <Route
            exact
            path={`${Routes.instructor.profile}`}
            component={InstructorProfile}
          />
          <Route
            exact
            path={`${Routes.courseSearch}`}
            component={CourseSearch}
          />
          <Route
            exact
            path={Routes.instructor.notifications}
            component={Notifications}
          />
          <Route
            exact
            path={Routes.instructor.earning}
            component={EarningPage}
          />
          <Route
            exact
            path={Routes.instructor.editCourse.audience(
              ':courseName/:editCourseId'
            )}
            component={EditCourseAudience}
          />
          <Route exact path={Routes.welcome} component={WelcomePage} />
          <Route
            exact
            path={Routes.instructor.editCourse.coursePreview(
              ':courseName/:editCourseId'
            )}
            component={CoursePreview}
          />
          <Route
            exact
            path={Routes.instructor.editCourse.landingPage(
              ':courseName/:editCourseId'
            )}
            component={EditCourseLandingPage}
          />
          <Route
            exact
            path={Routes.instructor.testVideo}
            component={TestVideo}
          />
          <Route exact path={Routes.cart} component={CartPage} />
          <Route exact path={Routes.course(':courseId')} component={Course2} />
          <Route
            exact
            path={Routes.instructor.editCourse.structure(
              ':courseName/:editCourseId'
            )}
            component={EditCourseStructure}
          />
          <Route
            component={ForCheck}
            exact
            path={Routes.instructor.editCourse.check(
              ':courseName/:editCourseId'
            )}
          />
          <Route
            exact
            path={Routes.instructor.editCourse.price(
              ':courseName/:editCourseId'
            )}
            component={CoursePricing}
          />
          <Route
            exact
            path={Routes.instructor.editCourse.price(
              ':courseName/:editCourseId'
            )}
          />
          <Route exact path={Routes.contact} component={ContactUsPage} />
          <Route
            exact
            path={Routes.instructor.editCourse.settings(
              ':courseName/:editCourseId'
            )}
            component={CourseSettings}
          />
          <Route
            exact
            path={Routes.instructor.editCourse.landigPagePreview(
              ':courseName/:editCourseId'
            )}
            component={LandingPagePreview}
          />

          <Route exact path={Routes.home} component={Home} />
          <Route exact path={Routes.login} component={Login} />
          <Route exact path={Routes.register} component={Register} />
          <Route exact path={Routes.auth} component={AuthSuccess} />
          <Route
            exact
            path={Routes.instructor.home}
            component={InstructorHome}
          />
          <Route
            exact
            path={Routes.instructor.courses}
            component={InstructorCourses}
          />
          <Route
            exact
            path={Routes.instructor.courseCreator}
            component={CourseCreator}
          />

          <Route
            exact
            path={`${Routes.instructor.id(':instructorId')}`}
            component={InstructorAbout}
          />
        </Switch>
        <Footer />
      </BrowserRouter>
    </Suspense>
  )
}

export default Router
