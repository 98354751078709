const JWT_TOKEN = 'JWT_TOKEN'

export const getAuthorizationHeader = (): { Authorization: string } => {
  const token = localStorage.getItem(JWT_TOKEN)
  return { Authorization: token ? `Bearer ${token}` : '' }
}

export const getToken = () => {
  return localStorage.getItem(JWT_TOKEN)
}

export const setToken = (token: string) => {
  localStorage.setItem(JWT_TOKEN, token)
}

export const removeToken = () => {
  localStorage.removeItem(JWT_TOKEN)
}
