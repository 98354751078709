// src/app/rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './slices/auth.slice'
import cartReducer from './slices/cart.slice'
import instructorCourseReducer from './slices/insturctorCourse.slice'
const rootReducer = combineReducers({
  authReducer,
  instructorCourseReducer,
  cartReducer,
})

export default rootReducer
