import React from 'react'
import { Oval } from 'react-loader-spinner'

interface Props {
  height?: number
  width?: number
  strokeWidh?: number
}

const Spinner: React.FC<Props> = ({ height, width, strokeWidh }) => {
  return (
    <Oval
      height={height}
      width={width}
      color="#FFFFFF"
      wrapperStyle={{
        'stroke-opacity': '1',
      }}
      wrapperClass=""
      visible={true}
      secondaryColor="#000000"
      strokeWidth={strokeWidh}
      strokeWidthSecondary={(strokeWidh as number) + 1}
    />
  )
}

Spinner.defaultProps = {
  height: 60,
  width: 60,
  strokeWidh: 9,
}

export default Spinner
