import Router from './Router'
import store from './redux/store'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import './main.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  BarElement,
} from 'chart.js'

ChartJS.register(
  BarElement,
  CategoryScale,
  Filler,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
)

export const App = () => {
  return (
    <div className="min-h-screen overflow-x-hidden">
      <Provider store={store}>
        <Router />
      </Provider>
    </div>
  )
}
