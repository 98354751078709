import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { axios } from '../../axios'
import { ApiRoutes } from '../../Router'
import { ResponseType } from '../../types/axios'
import { ICourse } from '../../types/course/course.interface'
import { ICart, ICartCourse } from '../../types/user/cart.interface'
import { IUser } from '../../types/user/user.interface'
import { getAuthorizationHeader, getToken } from '../../utils/authorization'
import { AppThunk, RootState } from '../store'

// import { RootState } from '../store'

export interface CartState {
  cart: ICart
  isLoading: boolean
  isDeleteLoading: boolean
  error: string
}

export const initialState: CartState = {
  isLoading: false,
  isDeleteLoading: false,
  error: 'An Error occurred',
  cart: { courses: [], totalCourses: 0, totalPrice: 0 },
}
export const getCart = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true))
    const { data }: ResponseType<IUser> = await axios.get(
      `${ApiRoutes.auth.me}?select=[cart]`,
      {
        headers: { ...getAuthorizationHeader() },
      }
    )

    dispatch(setCartSuccess(data.data.cart))
  } catch (error) {
    dispatch(setCartError((error as Error).message))
  } finally {
    dispatch(setLoading(false))
  }
}

export const deleteCourse =
  (courseId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setDeleteLoading(true))
    try {
      const { data }: ResponseType<ICart> = await axios.delete(
        `${ApiRoutes.users.cart}/${courseId}`,
        {
          headers: getAuthorizationHeader(),
        }
      )
      dispatch(setCartSuccess(data.data))
    } catch (_e) {
    } finally {
      dispatch(setDeleteLoading(false))
    }
  }

export const addCourse =
  (course: ICourse): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const cartCourse: ICartCourse = {
        thumbnailName: course.thumbnailName,
        thumbnailVideoServerId: course.thumbnailVideoServerId,
        totalDuration: course.totalDuration,
        rating: course.rating,
        totalRating: course.totalRating,
        instructorName: course.instructor.fullname,
        totalLectures: course.totalLectures,
        courseId: course._id,
        title: course.title,
        price: course.price,
      }
      const { data }: ResponseType<ICart> = await axios.put(
        `${ApiRoutes.users.cart}`,
        cartCourse,
        { headers: { ...getAuthorizationHeader() } }
      )
      dispatch(setCartSuccess(data.data))
    } catch (error) {
      dispatch(setCartError((error as Error).message))
    } finally {
      dispatch(setLoading(false))
    }
  }

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setCartSuccess: (state, { payload }: PayloadAction<ICart>) => {
      state.cart = payload
      state.isLoading = false
    },
    setCartError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload
      //   state.isAuth = false
    },
    setDeleteLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeleteLoading = payload
    },
  },
})

export const { setDeleteLoading, setCartError, setCartSuccess, setLoading } =
  cartSlice.actions

export const cartSelector = (state: RootState) => state.cartReducer

export default cartSlice.reducer
