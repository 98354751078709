// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { axios } from '../../axios'
import { ApiRoutes } from '../../Router'
import { ResponseType } from '../../types/axios'
import { IUser, User } from '../../types/user/user.interface'
import { getAuthorizationHeader } from '../../utils/authorization'
import { AppThunk, RootState } from '../store'

// import { RootState } from '../store'

export interface AuthState {
  isAuth: boolean
  currentUser: IUser
  isLoading: boolean
  error: string
}

export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  error: 'An Error occurred',
  currentUser: { username: '', email: 'undefined' },
}
export const login = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true))
    const { data }: ResponseType<User> = await axios.get(ApiRoutes.auth.me, {
      headers: { ...getAuthorizationHeader() },
    })
    dispatch(setAuthSuccess(data.data))
  } catch (error) {
    dispatch(setAuthFailed((error as Error).message))
  } finally {
    dispatch(setLoading(false))
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setAuthSuccess: (state, { payload }: PayloadAction<User>) => {
      state.currentUser = payload
      state.isAuth = true
    },
    setLogOut: (state) => {
      state.isAuth = false
      state.currentUser = undefined
    },
    setAuthFailed: (state, { payload }: PayloadAction<string>) => {
      state.error = payload
      //   state.isAuth = false
    },
  },
})

export const { setAuthSuccess, setLogOut, setLoading, setAuthFailed } =
  authSlice.actions

export const authSelector = (state: RootState) => state.authReducer

export default authSlice.reducer
