import { ICreatedCourse } from '../../types/user/createdCourse.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { axios } from '../../axios'
import { ApiRoutes } from '../../Router'
import { ResponseType } from '../../types/axios'
import { getAuthorizationHeader } from '../../utils/authorization'
import { AppThunk, RootState } from '../store'
import FormData from 'form-data'
import { AxiosRequestConfig } from 'axios'

interface InstructorCourseState {
  course: ICreatedCourse
  isLoading: boolean
  error: string
  loadingPercentage: number
  isUploadingVideo: string
  isNewSection: boolean
  isNewLecture: string
}

export const initialState: InstructorCourseState = {
  course: {} as ICreatedCourse,
  isUploadingVideo: '',
  isLoading: false,
  error: '',
  loadingPercentage: 0,
  isNewLecture: '',
  isNewSection: false,
}

export const instructorCourseSlice = createSlice({
  name: 'instructorCourse',
  initialState,
  reducers: {
    setLoading: (
      state,
      {
        payload,
      }: PayloadAction<{ isLoading: boolean; loadingPercentage?: number }>
    ) => {
      state.isLoading = payload.isLoading
      if (payload.loadingPercentage)
        state.loadingPercentage = payload.loadingPercentage
    },
    setRequestFailed: (state, { payload }: PayloadAction<string>) => {
      state.error = payload
    },
    setCourse: (state, { payload }: PayloadAction<ICreatedCourse>) => {
      state.course = payload
      state.isLoading = false
      state.loadingPercentage = 0
    },
    setIsUploadingVideo: (state, { payload }: PayloadAction<string>) => {
      state.isUploadingVideo = payload
    },

    setIsNewLecture: (state, { payload }: PayloadAction<string>) => {
      state.isNewLecture = payload
    },
    setIsNewSection: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewSection = payload
    },
  },
})

export const getInstructorCourse =
  (courseId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const { data }: ResponseType<ICreatedCourse> = await axios.get(
        `${ApiRoutes.users.createdCourses.id(courseId)}?select=[sections]`,
        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
    } catch (e) {
      dispatch(setRequestFailed((e as Error).message))
    } finally {
      dispatch(setLoading({ isLoading: false, loadingPercentage: 0 }))
    }
  }

export const deleteSection =
  (courseId: string, sectionId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const { data }: ResponseType<ICreatedCourse> = await axios.delete(
        `${ApiRoutes.users.createdCourses.sections.id(courseId, sectionId)}`,

        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      dispatch(setLoading({ isLoading: false }))
    }
  }

export const deleteLecture =
  (
    courseId: string,
    sectionId: string,
    lectureId: string,
    videoServerId: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const { data }: ResponseType<ICreatedCourse> = await axios.delete(
        `${ApiRoutes.users.createdCourses.sections.lectures.id(
          courseId,
          sectionId,
          lectureId
        )}/${videoServerId}`,
        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      dispatch(setLoading({ isLoading: false }))
    }
  }

export const updateSection =
  (
    courseId: string,
    sectionId: string,
    body: { [key: string]: any }
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const { data }: ResponseType<ICreatedCourse> = await axios.put(
        `${ApiRoutes.users.createdCourses.sections.id(courseId, sectionId)}`,
        body,
        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      setLoading({ isLoading: false })
    }
  }

export const updateLecture =
  (
    courseId: string,
    sectionId: string,
    lectureId: string,
    body: { [key: string]: any }
  ): AppThunk =>
  async (dispatch) => {
    try {
      const { data }: ResponseType<ICreatedCourse> = await axios.put(
        `${ApiRoutes.users.createdCourses.sections.lectures.id(
          courseId,
          sectionId,
          lectureId
        )}`,
        body,
        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      dispatch(setLoading({ isLoading: true }))
    }
  }
export const updateCourse =
  (courseId: string, body: { [key: string]: any }): AppThunk =>
  async (dispatch) => {
    try {
      const { data }: ResponseType<ICreatedCourse> = await axios.put(
        `${ApiRoutes.users.createdCourses.id(courseId)}`,
        body,
        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      dispatch(setLoading({ isLoading: true }))
    }
  }
export const createLecture =
  (
    courseId: string,
    sectionId: string,
    body: { [key: string]: any }
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const { data }: ResponseType<ICreatedCourse> = await axios.post(
        `${ApiRoutes.users.createdCourses.sections.lectures['/'](
          courseId,
          sectionId
        )}`,
        body,
        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
      dispatch(setIsNewLecture(''))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      dispatch(setLoading({ isLoading: false }))
    }
  }
export const createSection =
  (courseId: string, body: { [key: string]: any }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ isLoading: true }))
      const { data }: ResponseType<ICreatedCourse> = await axios.post(
        `${ApiRoutes.users.createdCourses.sections['/'](courseId)}`,
        body,
        { headers: getAuthorizationHeader() }
      )
      dispatch(setCourse(data.data))
      dispatch(setIsNewSection(false))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      dispatch(setLoading({ isLoading: false }))
    }
  }

export const uploadLectureVideo =
  (
    courseId: string,
    sectionId: string,
    lectureId: string,
    video: File
  ): AppThunk =>
  async (dispatch) => {
    try {
      const formData = new FormData()
      formData.append('video', video)
      const config: AxiosRequestConfig = {
        headers: {
          'Contet-Type': 'multipart/form-data',
          ...getAuthorizationHeader(),
        },
        onUploadProgress: (progressEvent) => {
          console.log('fuc this progress !')
          const percentage = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          console.log(progressEvent.total + ' <-- total')
          console.log(progressEvent.loaded + ' <-- loaded')
          dispatch(setIsUploadingVideo(lectureId))
          dispatch(
            setLoading({ loadingPercentage: percentage, isLoading: true })
          )
        },
      }
      const { data }: ResponseType<ICreatedCourse> = await axios.post(
        `${ApiRoutes.users.createdCourses.sections.lectures.id(
          courseId,
          sectionId,
          lectureId
        )}/videos`,
        formData,
        config
      )
      dispatch(setCourse(data.data))
    } catch (_e) {
      const e = _e as Error
      dispatch(setRequestFailed(e.message))
    } finally {
      dispatch(setIsUploadingVideo(''))
      dispatch(setLoading({ isLoading: true, loadingPercentage: 0 }))
    }
  }

export const {
  setRequestFailed,
  setLoading,
  setCourse,
  setIsNewLecture,
  setIsNewSection,
  setIsUploadingVideo,
} = instructorCourseSlice.actions

export const instructorCourseSelector = (state: RootState) =>
  state.instructorCourseReducer

export default instructorCourseSlice.reducer
